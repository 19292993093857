import React, {useEffect, useState, useContext} from 'react';
import {validate} from 'react-email-validator';
import { navigate } from 'gatsby';
import emailjs from 'emailjs-com'; // veraltete Lib
import './ContactForm.scss';

emailjs.init("user_1SjEpUCgt3RESRO624VTE");

export default function ContactForm() {

    const [valueName, setValueName] = useState('');
    const [validName, setValidName] = useState(false);
    const [emptyName, setEmptyName] = useState(true);

    const [valueMail, setValueMail] = useState('');
    const [validMail, setValidMail] = useState(false);
    const [emptyMail, setEmptyMail] = useState(true);

    const [valueMessage, setValueMessage] = useState('');
    const [validMessage, setValidMessage] = useState(false);

    const [submittingPossible, setSubmittingPossible] = useState(false);

    useEffect(() => {
        let isNameEmpty = valueName.length <= 0;
        setEmptyName(isNameEmpty);
        let isNameValid = validateName(valueName);
        setValidName(isNameValid);

        let isMailEmpty = valueMail.length <= 0;
        setEmptyMail(isMailEmpty);
        let isMailValid = validate(valueMail);
        setValidMail(isMailValid);

        let isMessageValid = valueMessage.length <= 300;
        setValidMessage(isMessageValid);

        if (isNameValid && isMailValid && isMessageValid) {setSubmittingPossible(true);}
        else {setSubmittingPossible(false);}

    }, [valueName, emptyName, valueMail, emptyMail, valueMessage])

    const validateName = (valueName: string) => {
        // ---> see https://regexr.com/
        // at least 2 words, each beginning with 1 capital letter, no numbers, no special characters
        const regex = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u);
        return regex.test(valueName);
    }

    const handleSubmitContactForm = (evt) => {
        if(!submittingPossible) {return;}
        evt.preventDefault();

        // Send Form via EmailJs
        const templateParams = {
            name: valueName,
            email: valueMail,
            message: valueMessage
        };

        console.log(templateParams);

        emailjs.send('service_tu3djg9', 'template_b4pvflb', templateParams)
            .then(function (response) {
                console.log('Email successfully sent!', response.status, response.text);
            }, function (err) {
                console.log('Failed to send email.', err);
            });

        backToMain();
    }

    const backToMain = () => {
        setSubmittingPossible(false);
        navigate("/#Kontakt");
    }

    return (
        <div className="ContainerContactForm">
            <div className="HeadingContactForm">Ihre Nachricht</div>
            <form className="ContactForm" onSubmit={handleSubmitContactForm}>
                <input className="NameInput" type="text" placeholder="Vorname Nachname" value={valueName} onChange={e => setValueName(e.target.value)}/>
                <div id="InvalidName" className="InvalidInput">{!validName && !emptyName && "Dies scheint kein gültiger Name zu sein."}</div>

                <input className="MailInput" type="text" value={valueMail} placeholder="E-Mail" onChange={e => setValueMail(e.target.value)}/>
                <div id="InvalidMail" className="InvalidInput">{!validMail && !emptyMail && "Dies scheint keine gültige E-Mail Adresse zu sein."}</div>

                <textarea className="MessageInput" value={valueMessage} placeholder="Ihre Nachricht" onChange={e => setValueMessage(e.target.value)}/>
                <div className="CharCount">{300 - valueMessage.length}/300 Zeichen</div>
                
                <button type="button" className="CancelButton" onClick={() => backToMain()}>Abbrechen</button>
                <input className="SendButton" type="submit" value="Senden" disabled={!submittingPossible}/>           
            </form>
        </div>
    );
}